import React from 'react'
import Helmet from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const Philosophy = () => (
  <Layout>
    <Helmet>
      <title>
        Élagage // Abattage // Arboriste // Grimpeur-Élagueur, taille et soins
        des arbres // Belgique
      </title>
      <meta
        name="description"
        content="Grimpeur-élagueur à Bruxelles, technicien spécialisé dans la taille raisonnée et le soin des arbres."
      />
    </Helmet>

    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>Philosophie</h1>
        </header>
        <div className="content">
          <p>
            Un professionnel qui vit son métier avec passion, <br />
            qu’il met au service des arbres.
          </p>
        </div>
      </div>
    </section>

    <div id="main">
      <section id="two" className="spotlights">
        <section>
          <StaticImage
            className="image"
            src={'../assets/images/pic09.jpg'}
            alt=""
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Préserver</h3>
              </header>
              <p>
                La taille raisonnée est une pratique respectueuse de la
                physiologie de l’arbre. Cette méthode répond à tout type de
                contrainte tout en taillant le moins possible. Elle préserve le
                port naturel, la santé, la longévité et l’esthétique de l’arbre.
              </p>
              <blockquote>
                « L’arbre n’a pas besoin d’être taillé... »
              </blockquote>
            </div>
          </div>
        </section>
        <section>
          <StaticImage
            className="image"
            src={'../assets/images/pic13.jpg'}
            alt=""
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Respecter</h3>
              </header>
              <p>
                Quel que soit le geste, il s'agit toujours de rendre à l’arbre
                son sens réel, de respecter son identité biologique. Afin
                d’intervenir de manière raisonnée, il est nécessaire de prendre
                connaissance de la structure du houppier et d'analyser en
                profondeur son individualité et ses spécificités, et ce, à
                chaque étape de l'intervention.
              </p>
            </div>
          </div>
        </section>
        <section>
          <StaticImage
            className="image"
            src={'../assets/images/pic12.jpg'}
            alt=""
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Comprendre l'ensemble</h3>
              </header>
              <p>
                L'arbre n'est jamais seul. Il participe à la richesse de
                l'écosystème qu'il soutient. Sa couverture joue un rôle
                fondamental pour la faune et la flore qui l'entourent. Avant
                toute action, il s'agira toujours de comprendre son impact à une
                échelle plus grande que sa seule individualité, et ce, tant pour
                l'esthétique de l'ensemble que pour la variété biologique du
                lieu.
              </p>
              <blockquote>« L'arbre est un monde à lui seul... »</blockquote>
            </div>
          </div>
        </section>
        <section>
          <StaticImage
            className="image"
            src={'../assets/images/pic10.jpg'}
            alt=""
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sécuriser</h3>
              </header>
              <p>
                Sensible aux aspects réglementaires et en pleine conscience en
                matière de sécurité, grimper dans les arbres se fait toujours
                avec humilité, vigilance et prudence. Nicolas met à profit ses
                connaissances et son expérience afin de protéger le patrimoine
                arboré, tout en intervenant quand l’homme et son environnement
                ne sont plus en harmonie.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Philosophy
